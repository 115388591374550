import { createSlice } from '@reduxjs/toolkit'

export const TrainingSlice = createSlice({
  name: 'training',
  initialState: {
    completed: null,
    completedError: null,

    progress: null,
    progressError: null,

    saveStatus: null,
    resetStatus: null,

    result: null,
    resultError: null
  },
  reducers: {
    updateCompleted: (state, action) => {
      state.completed = action.payload;
      state.completedError = null;        
    },
    notifyCompletedError: (state, action) => {
      state.completedError = action.payload;
      state.completed = null;        
    },
    
    updateProgress: (state, action) => {
      state.progress = action.payload;
      state.progressError = null;        
    },
    notifyProgressError: (state, action) => {
      state.progressError = action.payload;
      state.progress = null;        
    },
    refreshProgress: (state) => {
      state.progress = null;
      state.progressError = null;        
    },
  
    updateSaveStatus: (state, action) => {
        state.saveStatus = action.payload
    },

    updateResetStatus: (state, action) => {
      state.resetStatus = action.payload
    },
    refreshResetStatus: (state) => {
      state.resetStatus = null
    },

    updateResult: (state, action) => {
        state.result = action.payload;
        state.resultError = null;        
    },
    notifyResultError: (state, action) => {
        state.resultError = action.payload;
        state.result = null;        
    },
    refreshResult: (state) => {
      state.result = null;
      state.resultError = null;        
    },
  }
})

// Action creators are generated for each case reducer function
export const { updateCompleted, notifyCompletedError, updateProgress, notifyProgressError, refreshProgress, updateSaveStatus, updateResult, notifyResultError, refreshResult, updateResetStatus, refreshResetStatus } = TrainingSlice.actions

export default TrainingSlice.reducer