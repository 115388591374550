import React, { useState, useEffect } from "react";
import { Text, Logo, Icon } from 'basis';
import { connect, useDispatch } from "react-redux";
import Button from '@mui/material/Button';
import { Navigate } from 'react-router-dom';

import Question from '../components/Question';
import LinearProgressWithLabel from "../components/LinearProgressWithLabel";
import { updateUserDetails } from '../slice/UserSlice';
import { getCompleted, getProgress, saveProgress, submit, resetProgress } from '../functions/TrainingFunctions';
import Divider from "../components/Divider";
import { ReactComponent as CompletedIcon } from '../images/completed.svg';
import { ReactComponent as FailedIcon } from '../images/failed.svg';
import { refreshResult, refreshResetStatus, refreshProgress } from '../slice/TrainingSlice';
import { arraysEqual, getQuiz, randomizeArray } from "../functions/utils";
import { logout } from '../functions/UserFunctions';

const mapStateToProps = (state) => ({
  authDetails: state.user.authDetails,
  completed: state.training.completed,
  completedError: state.training.completedError,
  progress: state.training.progress,
  progressError: state.training.progressError,
  saveStatus: state.training.saveStatus,
  resetStatus: state.training.resetStatus,
  result: state.training.result,
  resultError: state.training.resultError
});

const mapDispatchToProps = { getCompleted, getProgress, saveProgress, submit, refreshResult, resetProgress, refreshResetStatus, refreshProgress, logout };

function Quiz(props) {
    const [progress, setProgress] = useState({});
    const [percentCompleted, setpercentCompleted] = useState(0);
    const [isSuccess, setSuccess] = useState(false);
    const [isFailed, setFailed] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [isNewLoad, setIsNewLoad] = useState(true);


    //UI state
    const [width, setWidth] = useState(window.innerWidth);

    const MODULE_NAME = window.location.pathname.split('/')[2];
    const quiz = getQuiz(MODULE_NAME);
    const Q_PER_PAGE = 5;

    const dispatch = useDispatch();
    if (!props.authDetails){
        console.log('retrieving auth from session', JSON.parse(window.sessionStorage.getItem('authDetails')))
        dispatch(updateUserDetails(JSON.parse(window.sessionStorage.getItem('authDetails'))));
    }

    const [ questionSet, setQuestions ] = useState(randomizeArray(quiz).slice(0, Q_PER_PAGE).sort((a,b)=>a-b));

    const {getCompleted, getProgress, refreshResetStatus} = props;
    useEffect(() => {
            //viewport listener
            function handleResize(){
                setWidth(window.innerWidth);
            }
            window.addEventListener('resize', handleResize);

            //auth expired or logged out
            if (!props.authDetails && !window.sessionStorage.getItem('authDetails')){
                setRedirect(true);
            }

            //initial progress load from API
            if (props.authDetails && isNewLoad && props.authDetails.authToken && !props.progress){
                getCompleted(props.authDetails.authToken);
                getProgress(props.authDetails.authToken);
            }

            if (props.completed && props.completed[MODULE_NAME]){
                setSuccess(true);
            }

            //progress from API not empty, but current progress is empty
            if(props.progress 
                && props.progress[MODULE_NAME] 
                && props.progress[MODULE_NAME].quiz 
                && Object.keys(props.progress[MODULE_NAME].quiz).length !== 0 
                && Object.keys(progress).length === 0){
                    let loadedProgress = props.progress[MODULE_NAME].quiz;
                    setProgress(loadedProgress);
                    setQuestions(Object.keys(loadedProgress));
            }

            //progress from API is empty and current progress is empty , use randomized question set to initialize progress  
            if(props.progress 
                && (!props.progress[MODULE_NAME] || !props.progress[MODULE_NAME].quiz) 
                && Object.keys(progress).length === 0){
                    let prog = {};
                    questionSet.forEach(qId => prog[qId] = '');
                    setProgress(prog);
            }

            //on update progress
            let answered = 0;
            Object.values(progress).forEach(answer => {
                if (answer !== '')
                    answered++;
            })
            setpercentCompleted((answered/Q_PER_PAGE)*100);

            //on getting result from submit API
            if (props.result && parseInt(props.result.score) < 100 )
                setFailed(true);
            if (props.result && parseInt(props.result.score) === 100)
                setSuccess(true);

            //on try again
            if (props.resetStatus === 'success'){
                getProgress(props.authDetails.authToken);
                refreshResetStatus();
                //reset state and redux
                setProgress({});
                setQuestions(randomizeArray(quiz).slice(0, Q_PER_PAGE));
                setpercentCompleted(0);
                setFailed(false);
                setSuccess(false);
            }

            //remove listener
            return function removeListener(){
                window.removeEventListener('resize', handleResize);
            }
        }, 
        [
            props.authDetails, props.progress, props.progressError, props.completed,
            props.saveStatus, props.result, props.resultError, props.resetStatus, quiz,
            getCompleted, getProgress, MODULE_NAME, progress, questionSet, refreshResetStatus, isNewLoad
        ]
    );

    const handleAnswer = (key, val) => {
        let currentProgress = {...progress};
        currentProgress[key] = val;
        setProgress(currentProgress);
        let saveObj = {
            name: MODULE_NAME,
            data: {
                quiz: currentProgress
            }
        };

        props.saveProgress(props.authDetails.authToken, saveObj);
    }

    const renderSuccess = props.authDetails ? (
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <CompletedIcon/>
            <div style={{marginTop:20}}>
                <Text as="h5" textStyle="heading5">Congratulations!</Text>
            </div>
            <div style={{marginTop: 10, marginBottom: document.referrer !== '' && props.authDetails.loginType === 'okta' ? 0 : 30}}>
                <Text textStyle="subtitle2">You've completed this training module</Text>
                {
                    (document.referrer === '' || props.authDetails.loginType !== 'okta') &&
                    <Text align='center' textStyle="subtitle2">You may now close this browser</Text>
                }
            </div>
            {
                document.referrer !== '' && props.authDetails.loginType === 'okta' &&
                <Button variant="contained"
                    style={{width: 200, height: 50, backgroundColor: '#0061EE', textTransform: 'none', fontFamily:'Roboto', fontWeight:500, fontSize:18, marginTop:40}} 
                    onClick={()=>{ 
                        window.close();
                        if (window.closed === false) {
                            alert("Sorry, you'll have to close this browser tab yourself.");
                        }
                    }}>
                    Close
                </Button>
            }
        </div>
    ) : null;

    const renderFail = (
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <FailedIcon/>
            <div style={{marginTop:30}}>
                <Text as="h5" textStyle="heading5">You'll need to try again</Text>
            </div>
            <div style={{marginTop: 10}}>
                <Text textStyle="subtitle2">Unfortunately, you did not pass this training module.</Text>
            </div>
            <div style={{marginTop: 10}}>
                <Text textStyle="subtitle2">
                    In order to meet compliance for <strong>Latitude Checkout</strong>, you'll need to repeat this module.
                </Text>
            </div>
            <Button variant="contained"
                style={{width: 200, height: 50, backgroundColor: '#0061EE', textTransform: 'none', fontFamily:'Roboto', fontWeight:500, fontSize:18, marginTop:30}} 
                onClick={()=>{ 
                    //reset backend progress and redux's result
                    props.resetProgress(props.authDetails.authToken, MODULE_NAME);
                    setIsNewLoad(false); //so that the new load condition is not triggered
                    props.refreshResult();
                    props.refreshProgress();
                }}>
                Try again
            </Button>
        </div>
    ); 

    const renderQuiz = (
        <React.Fragment>
            <LinearProgressWithLabel variant="determinate" value={percentCompleted} direction={width > 750 ? 'row' : 'column'} style={{marginRight: width > 750 ? 25 : 0, height: 8, borderRadius: 8, backgroundColor: '#E5E5E5'}}/>
            <Divider style={{marginBlock:32}}/>
            {
                //only render when progress and questionSet aligns 
                arraysEqual(Object.keys(progress),questionSet) &&
                questionSet.map((key, idx) => {
                    return <Question   
                        key={idx}
                        style={{marginBottom:40}}
                        question={quiz[key].question}
                        choice={quiz[key].choice}
                        multiple={quiz[key].multiple}
                        answer={progress[key]}
                        qId={key}
                        qNumber={idx+1}
                        onChange={(val) => handleAnswer(key, val)}
                    />
                })
            }
            <div style={{display:'flex', flexDirection: 'row', justifyContent: width > 750 ? 'flex-start' : 'center'}}>
                <Button variant="contained"
                    disabled={percentCompleted<100}
                    style={{width: 200, height: 50, backgroundColor: percentCompleted < 100 ? null : '#0061EE', textTransform: 'none', fontFamily:'Roboto', fontWeight:500, fontSize:18 }} 
                    onClick={()=>{ 
                        let saveObj = {
                            name: MODULE_NAME,
                            data: {
                                quiz: progress
                            }
                        };
                        props.submit(props.authDetails.authToken, saveObj)
                    }}>
                    Submit
                </Button>
            </div>
        </React.Fragment>
    );

    const renderName = () => {
        let firstname = `${props.authDetails.firstName.charAt(0).toUpperCase()}${props.authDetails.firstName.slice(1)}`;
        let lastname = props.authDetails.lastName ? `${props.authDetails.lastName.charAt(0).toUpperCase()}${props.authDetails.lastName.slice(1)}` : '';

        return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 20}}>
            <Icon name="person" color="primary.blue.t100" />
            <Text as="h6" textStyle="heading6" color="primary.blue.t100">
                {`Hi, ${firstname} ${lastname}`}
            </Text>
        </div>
    };

    return (
        <div style={{minHeight: window.innerHeight, backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' viewBox=\'0 0 907.087 907.087\'%3E%3Cdefs%3E%3ClinearGradient id=\'linear-gradient\' x1=\'0.5\' y1=\'0.006\' x2=\'0.5\' y2=\'1.993\' gradientUnits=\'objectBoundingBox\'%3E%3Cstop offset=\'0\' stop-color=\'%23f2eeea\'/%3E%3Cstop offset=\'0.537\' stop-color=\'%23fff\'/%3E%3C/linearGradient%3E%3CclipPath id=\'clip-path\'%3E%3Crect id=\'Rectangle_2\' data-name=\'Rectangle 2\' width=\'907.087\' height=\'907.087\' transform=\'translate(0 3796.621)\' fill=\'url(%23linear-gradient)\'/%3E%3C/clipPath%3E%3ClinearGradient id=\'linear-gradient-3\' x1=\'0.196\' y1=\'0.5\' x2=\'-0.298\' y2=\'0.5\' xlink:href=\'%23linear-gradient\'/%3E%3ClinearGradient id=\'linear-gradient-4\' x1=\'0.237\' y1=\'0.5\' x2=\'0.503\' y2=\'0.5\' xlink:href=\'%23linear-gradient\'/%3E%3C/defs%3E%3Cg id=\'Group_2\' data-name=\'Group 2\' transform=\'translate(0 -3796.621)\'%3E%3Crect id=\'Rectangle_1\' data-name=\'Rectangle 1\' width=\'907.087\' height=\'907.087\' transform=\'translate(0 3796.621)\' fill=\'url(%23linear-gradient)\'/%3E%3Cg id=\'Group_1\' data-name=\'Group 1\' clip-path=\'url(%23clip-path)\'%3E%3Cpath id=\'Path_1\' data-name=\'Path 1\' d=\'M1921.905 2783.693l-272.5-190.805L559.022 4150.119 2116.252 5240.5 2307.057 4968 1022.326 4068.426Z\' fill=\'url(%23linear-gradient-3)\'/%3E%3Cpath id=\'Path_2\' data-name=\'Path 2\' d=\'M1873.11 1690.318l-538.47-377.041L-820.018 4390.447 2257.15 6545.1l377.041-538.47L95.494 4229.018Z\' fill=\'url(%23linear-gradient-4)\'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")'}}>
            {redirect && <Navigate to="/"/>}
            <div style={{background: '#FFFFFF', boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.05)', padding: '16px 32px', display: 'flex', flexDirection: width > 600 ? 'row' : 'column', alignItems: 'center', justifyContent:'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: width > 600 ? 0 : 30}}>
                    <Logo  name="latitude" color="primary.blue.t100"/>
                    <div style={{marginLeft: 24}}>
                        {
                            width > 600 ? 
                                <Text as="h5" textStyle="heading5" color="primary.blue.t100">Training Module</Text>
                                :
                                <Text as="h6" textStyle="heading6" color="primary.blue.t100">Training Module</Text>
                        }
                    </div>
                    {
                        width <= 600 && width > 280 &&
                        <div style={{cursor: "pointer", marginRight: 20, marginLeft: width > 400 ? 20 : 0}} onClick={()=>props.logout()}>
                            <Icon name="logout" color="primary.blue.t100" />
                        </div>
                    }
                </div>
                { width <= 600 && <Divider style={{marginBlock:10}}/> }
                <div style={{justifySelf: 'flex-end', display: 'flex', flexDirection: 'row'}}>
                    { props.authDetails && props.authDetails.firstName && renderName() }
                    {
                        (width > 600 || width <= 280) &&
                        <div style={{cursor: "pointer", marginRight: 20}} onClick={()=>props.logout()}>
                            <Icon name="logout" color="primary.blue.t100" />
                        </div>
                    }
                </div>
            </div>
            <div style={{display:'flex', paddingBlock: 40, justifyContent: 'center', flexDirection: 'column'}}>
                {
                   !isSuccess && !isFailed &&
                    <div style={{background: '#FFFFFF', width: width > 750 ? '60%' : '75%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, alignSelf: 'center', padding:32, marginBottom:32}}>
                        <Text as="h5" textStyle="heading5" >Read our Latitude training content</Text>
                        <div style={{marginTop:20}}>
                            <Text>
                                The following link accesses training content to provide guidance on what you need to do to comply with Anti-Money Laundering/Counter-Terrorism Financing legislation and your responsibilities under Responsible Lending. This training needs to be completed and acknowledged prior to accepting Latitude's products.
                            </Text>
                        </div>
                        <div style={{marginBlock:10}}>
                            <Text>
                                Once you have reviewed the training content, please complete the below questionnaire. You will need to correctly answer all 5 questions in order to proceed.
                            </Text>
                        </div>
                        <Button variant="outlined"
                            style={{width: width > 375 ? 300 : width > 280 ? 250 : 200, textTransform: 'none', fontFamily:'Roboto', fontWeight:500, fontSize:18, marginTop:10, paddingBlock: 5 }} 
                            onClick={()=>{ 
                                window.open('https://rise.articulate.com/share/qkEVXZvqrvjPHrL7b2UwxaIWEotNTyLY', '_blank', 'noopener,noreferrer');
                            }}>
                            Read the training content
                        </Button>
                    </div>
                }
                <div style={{background: '#FFFFFF', width: width > 750 ? '60%' : '75%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, alignSelf: 'center', padding:32}}>
                    {isSuccess ? renderSuccess : isFailed ? renderFail : renderQuiz}
                </div>
            </div>
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Quiz);