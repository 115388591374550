export const page1 = 
    {
        title: 'Pay-by-Link Training and Knowledge Check',
        description: 'This training content outlines the minimum knowledge required to process sales with Latitude Interest Free.',
        sections: [
            {
                heading: 'Introduction',
                info: ['Pay-by-link allows you to:'],
                checks: [
                    {
                        info: 'Process Interest Free sales and returns for existing Latitude customers',
                        isChecked: false
                    },
                    {
                        info: 'Generate Quotes for new Customers enquiring about Interest Free, who can then apply for a Latitude Credit Card via Latitude\'s website before completing their purchase',
                        isChecked: false
                    }
                ]
            },
            {
                heading: 'Use of Latitude systems: Security and guiding principles',
                infoBoxes: [
                    {
                        title: 'Security',
                        info: 'Only ever use your own account for Latitude\'s systems, never borrow anyone else\'s account or share your account details',
                        isChecked: false
                    },
                    {
                        title: 'Privacy',
                        info: 'Be mindful of protecting customer information, by avoiding wrting information down and entering it directly into Latitude system',
                        isChecked: false
                    },
                    {
                        title: 'Do the right thing',
                        info: 'When in doubt, call the Latitude Service Desk on XXX to avoid providing false or misleading information, and never pressure a customer to apply for a Latitude product',
                        isChecked: false
                    }
                ]
            },
            {
                heading: 'Who can apply for Latitude Interest Free?',
                checks: [
                    {
                        info: 'Over 18',
                        isChecked: false
                    },
                    {
                        info: 'An Australian resident of visa permission to remain for over 12 months',
                        isChecked: false
                    }
                ],
                footnote: 'Applications usually take 10 to 15 minutes and they will require to prove their identity and undergo automated financial credit checks.'
            },
            {
                heading: 'Assisting new customers to verify their identity during application',
                info: [
                    'Where customers require assistance in proving their identity, please call Latitude Service Desk on XXX who will direct you on the identification process.',
                    'Be observant of customers\' behaviours that may appear unusual, such as:'
                ],
                checks: [
                    {
                        info: 'Acting Nervously',
                        isChecked: false
                    },
                    {
                        info: 'Reluctance or unwillingness to provide information',
                        isChecked: false
                    },
                    {
                        info: 'Presenting unusual, supsicious or falsified documents',
                        isChecked: false
                    },
                    {
                        info: 'The customer does not appear to be who they claim to be',
                        isChecked: false
                    }
                ],
                footnote: 'If your suspicions are raised, alert the Latitude Service Desk team member who will guide you how to safely proceed'
            },
        ]
    };

export const quiz = 
    {
        1 : {
            question: 'If your customer has any questions regarding their application for a Latitude credit card or they encounter any difficulties or concerns, you are to?',
            choice: [
                { value: 'a', label: 'Direct them to the dedicated Latitude contact number on  1300 660 134' },
                { value: 'b', label: 'Try to answer their questions and assist them in completing their application  direct with Latitude' }
            ],
            multiple: false
        },
        2 : {
            question: 'For new customers, you are able to assist verifying your customer\'s identification by calling the Latitude service Desk?',
            choice: [
                { value: 'a', label: 'Yes' },
                { value: 'b', label: 'No' }
            ],
            multiple: false
        },
        3 : {
            question: 'How would you describe the Latitude Gem Visa credit card to a customer?',
            choice: [
                { value: 'a', label: 'Latitude Gem Visa is a credit card that gives the customer access to interest free payment plans, and the ability to make everyday credit card purchases and/or perform other transactions as required' },
                { value: 'b', label: 'Latitude Gem Visa is a credit card that gives the customer access to interest free payment plans only' },
                { value: 'c', label: 'Latitude GEM Visa is a store card that you can only use at participating retailers' }
            ],
            multiple: false
        },
        4 : {
            question: 'Where would you go to find out what the monthly account service fee is for the Latitude Gem Visa credit card?',
            choice: [
                { value: 'a', label: 'The Latitude Gem Visa product website' },
                { value: 'b', label: 'Latitude Gem Visa Key Fact Sheet' },
                { value: 'c', label: 'Both' }
            ],
            multiple: false
        },
        5 : {
            question: 'Someone who is 16 years old can apply for a Latitude Gem Visa are a credit card?',
            choice: [
                { value: 'a', label: 'True' },
                { value: 'b', label: 'False' }
            ],
            multiple: false
        },
        6 : {
            question: 'The 3 different promotion types offered on the Latitude Gem Visa credit card are; Minimum monthly, flexible & equal monthly.',
            choice: [
                { value: 'a', label: 'True' },
                { value: 'b', label: 'False' },
            ],
            multiple: false
        },
        7 : {
            question: 'When helping to verify a customer\'s identity over the phone with Latitude, the customer seems quite nervous and has questioned why they need to provide identification. After you answer their question, they reluctantly provide you with their driver\'s licence. You are suspicious, as the photo doesn\'t look like them. Do you:',
            choice: [
                { value: 'a', label: 'Confirm the identity, it must be them as they have the driver\'s licence' },
                { value: 'b', label: 'Contact Latitude on 1300 361 921 and ask for extension 500. You will then be guided on how to safely proceed' }
            ],
            multiple: false
        },
        8 : {
            question: 'To be eligible to apply for a Latitude card that supports Interest Free Payment Plans. a customer must be:',
            choice: [
                { key: 'a', label: 'Over 18 years old' },
                { key: 'b', label: 'A resident of Australia or a temporary resident who legally has the right to work in Australia (and intends to stay) for at least the next 12 months' }
            ],
            multiple: true
        },
        9 : {
            question: 'If your customer does not pay off the total balance of their purchase before the promotional period ends, they will be charged interest -',
            choice: [
                { value: 'a', label: 'Which is backdated from the date the promotion plan started' },
                { value: 'b', label: 'On any remaining balance after the promotional period ends' }
            ],
            multiple: false
        },
        10 : {
            question: 'Is the customer charged an early payout penalty fee if they wish to payout their plan early?',
            choice: [
                { value: 'a', label: 'Yes' },
                { value: 'b', label: 'No' }
            ],
            multiple: false
        },
        11 : {
            question: 'If you need to know more information about the Latitude Gem Visa credit card, where can you find this?',
            choice: [
                { value: 'a', label: 'Your Latitude Account Manager or Inside Sales Associate' },
                { value: 'b', label: 'The Latitude Service Desk on 1300 361 921' },
                { value: 'c', label: 'Latitude product websites' },
                { value: 'd', label: 'All of the above' }
            ],
            multiple: false
        },
        12 : {
            question: 'You can introduce interest free at any point of your discussion provided you inform the customer of all payment options at the same time?',
            choice: [
                { value: 'a', label: 'True' },
                { value: 'b', label: 'False' },
            ],
            multiple: false
        },
        13 : {
            question: 'When your customer has asked about interest free payment plans what can you discuss with them',
            choice: [
                { value: 'a', label: 'Factual information relating to how the interest fee payment plan works' },
                { value: 'b', label: 'Current interest free promotions that you are running and/or what everyday interest free payment plans you have available' },
                { value: 'c', label: 'Estimated monthly payments (equal monthly payment promotions only)' },
                { value: 'd', label: 'All of the above' }
            ],
            multiple: false
        },
        14 : {
            question: 'It is important to introduce all the payment options at the same time so that the customer may choose the most suitable payment option for their needs',
            choice: [
                { value: 'a', label: 'True' },
                { value: 'b', label: 'False' },
            ],
            multiple: false
        },
        15 : {
            question: 'Is it important to remember that you cannot use interest free as a payment option to encourage your customer to purchase more?  (for example, don\'t say things like “Interest free is a great way for you to take home what you really want and have the ability to pay it off over time. For an extra $5 per week you can have the complete bundle or package”.)',
            choice: [
                { value: 'a', label: 'True' },
                { value: 'b', label: 'False' },
                { value: 'c', label: 'Not sure' },
            ],
            multiple: false
        },
        16 : {
            question: 'If you ﬁnd your customers are having diﬃculty understanding you or appear confused, what should you do',
            choice: [
                { value: 'a', label: 'Please slow down, speak clearly, offer alternative explanations and check for understanding' },
                { value: 'b', label: 'Don\'t interrupt or show impatience' },
                { value: 'c', label: 'Seek conﬁrmation' },
                { value: 'd', label: 'All of the above' },
            ],
            multiple: false
        },
        17 : {
            question: 'You need to advise your customer that they are applying for a credit card with Latitude Gem Visa.',
            choice: [
                { value: 'a', label: 'True' },
                { value: 'b', label: 'False' },
            ],
            multiple: false
        },
        18 : {
            question: 'Harass means any of the following “annoy, bother, persecute, tease or torment”',
            choice: [
                { value: 'a', label: 'True' },
                { value: 'b', label: 'False' },
            ],
            multiple: false
        },
        19 : {
            question: 'You cannot offer any form of credit or personal advice, stick to factual information only',
            choice: [
                { value: 'a', label: 'True' },
                { value: 'b', label: 'False' },
            ],
            multiple: false
        },
        // 20 : {
        //     question: 'Harass means any of the following “annoy, bother, persecute, tease or torment”',
        //     choice: [
        //         { value: 'a', label: 'True' },
        //         { value: 'b', label: 'False' },
        //     ],
        //     multiple: false
        // },
    }
