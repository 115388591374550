import React, { useState, useEffect, useCallback } from "react";
import { Text, Icon, LoadingIcon, Logo } from 'basis';
import { connect } from "react-redux";
import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import { Navigate, useSearchParams } from 'react-router-dom';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

import Input from '../components/Input';
import { register, get2FA, login, oktaLogin } from '../functions/UserFunctions';
import { refreshAuth } from "../slice/UserSlice";
import { ReactComponent as UnauthorizedIcon } from '../images/failedRed.svg';

const mapStateToProps = (state) => ({
  authDetails: state.user.authDetails,
  authError: state.user.authError
});

const mapDispatchToProps = { register, get2FA, login, oktaLogin, refreshAuth };

function Login(props) {
  // Form states
  const [passwordPage, setPasswordPage] = useState(false);
  const [loginPage, setLoginPage] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [merchantId, setMerchantId] = useState('');
  const [tnc, setTnc] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [moduleName, setModuleName] = useState('training1');
  
  //captcha
  const [captcha, setReCaptchaToken] = useState('');
	const {executeRecaptcha} = useGoogleReCaptcha();

  //UI state
  const [width, setWidth] = useState(window.innerWidth);

  const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha)
			return;

    console.log('refresh captcha');

    let token = null
    try{
      console.log('replace captcha')
      token = await executeRecaptcha('register');
      setReCaptchaToken(token);
    }
    catch (e){
      console.log(e)
      if (!captcha){
        setLoadingPage(true);
        setTimeout(()=>window.location.reload(),2000);
      }
    }
  
	}, [executeRecaptcha, captcha]);

  const {oktaLogin} = props;
  useEffect(() => {
    //redirect straight from PBL link 
    let accessToken = searchParams.get("accessToken");
    let merchantId = searchParams.get("merchantId");
    let firstName = searchParams.get("firstName");
    let lastName = searchParams.get("lastName");
    if (accessToken && merchantId && firstName && lastName && !error){
      setLoadingPage(true);
      oktaLogin({
        accessToken,
        merchantId,
        firstName,
        lastName
      });
    }

    //get module name
    let module = searchParams.get("module");
    if (module){
      setModuleName(module);
    }

    if (!captcha)
      handleReCaptchaVerify();

    //viewport listener
    function handleResize(){
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    if (props.authDetails){
      if (props.authDetails.message){
        setLoading(false);
        setPasswordPage(true);
        setMessage(props.authDetails.message);
      }
      if (props.authDetails.authToken){
        setLoading(false);
        window.sessionStorage.setItem('authDetails', JSON.stringify(props.authDetails));
        setRedirect(true);
      }
    }

    if (props.authError){
      setLoading(false);
      setError(props.authError)
    }

    //remove listener
    return function removeListener(){
      window.removeEventListener('resize', handleResize);
    }
  }, [props.authDetails, props.authError, searchParams, oktaLogin, handleReCaptchaVerify, captcha, error])

  const validateUsername = (username) => {
    if (username === ''){
      setError('Email or Mobile number cannot be empty');
      return false;
    }

    if (username.split('@').length > 2){
      setError('Invalid email');
      return false;
    }

    let isEmail = username.split('@').length === 2;
    
    if (isEmail){
      let email =  username.split('@');
      if (email[0].split(' ').length > 1 || email[1].split(' ').length > 1){
        setError('Email cannot have space');
        return false;
      }
      if (email[1].split('.').length === 1){
        setError('Invalid email domain');
        return false;
      }
      return 'email';
    }

    if (!isEmail){
      let phone = username.replace(/[^\d]/g, '');
      if (isNaN(phone)){
        setError('Please enter a valid phone number');
        return false;
      }
      
      if (!phone.startsWith('04')){
        setError('Phone number must start with 04');
        return false;
      }

      if (phone.length !== 10){
        setError('Invalid AU phone number length');
        return false;
      }
      
      // let areaCode = phone.substring(0,2);
      // if (areaCode !== '61' && areaCode !== '64'){
      //   setError('Please use only AU(61) or NZ(64) number');
      //   return false;
      // }

      // if (areaCode === '64'){
      //   if (phone.length < 10 || phone.length > 12){
      //     setError('Invalid NZ phone number length');
      //     return false;
      //   }
      // }

      // if (areaCode === '61'){
      //   if (phone.length !== 11){
      //     setError('Invalid AU phone number length');
      //     return false;
      //   }
      // }
      return 'mobile';
    }
  };

  const validateInput = () => {
    if (firstName === ''){
      setError('First name cannot be empty');
      return false;
    }

    if (merchantId === ''){
      setError('Merchant ID cannot be empty');
      return false;
    }

    if (tnc === false){
      setError('Please accept Privacy Policy before proceeding');
      return false;
    }

    return true;
  }

  const onInputChange = (event, key) => {
    handleReCaptchaVerify();

    switch (key) {
      case 'username': setUsername(event.target ? event.target.value : event.value); break;
      case 'password': setPassword(event.target ? event.target.value : event.value); break;
      case 'firstname': setFirstName(event.target ? event.target.value : event.value); break;
      case 'lastname': setLastName(event.target ? event.target.value : event.value); break;
      case 'merchantId': setMerchantId(event.target ? event.target.value : event.value); break;
      default: break;
    }
  };

  const handleRegister = (e) =>{
      handleReCaptchaVerify();

      e.preventDefault();

      if(!captcha) {
        setError('Recaptcha error');
        return;
      }

      setLoading(true);
      props.refreshAuth();
      setError('');
      setMessage('');
      let type = validateUsername(username);
      let isComplete = validateInput();
      
      if (type && isComplete){
        let id = type === 'mobile' ? username.replace(/[^\d]/g, '') : username;
        props.register(
            {
              id,
              type,
              firstName,
              lastName,
              merchantId,
              captcha,
              tnc: tnc ? "I agree to Latitude collecting my personal information." : ''
            }
        );
      }
      else
        setLoading(false);
  }

  const renderSignup = (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', padding: 30, width: width > 750 ? 450 : 0.7*width, height: 550, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
        <Text as="h4" align='center' textStyle="heading4">Merchant User Questionnaire</Text>
        <div style={{marginTop: 5}}>
          <Text as="h4" align='center' textStyle="heading4">Sign Up</Text>
        </div>
        <form onSubmit={handleRegister} style={{width:'100%', display:'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Input
              id='username'
              placeholder='Email or AU Mobile number'
              value={username}
              style={{marginTop: 20, width: '90%'}}
              onChange={e=>onInputChange(e, 'username')}
            />
            <Input
              id='firstname'
              placeholder='First name'
              value={firstName}
              style={{marginTop: 5, width: '90%'}}
              onChange={e=>onInputChange(e, 'firstname')}
            />
            <Input
              id='lastname'
              placeholder='Last name'
              value={lastName}
              style={{marginTop: 5, width: '90%'}}
              onChange={e=>onInputChange(e, 'lastname')}
            />
            <Input
              id='merchantId'
              placeholder='Merchant ID'
              value={merchantId}
              style={{marginTop: 5, width: '90%'}}
              onChange={e=>onInputChange(e, 'merchantId')}
            />

            <div style={{marginTop: 20, width:'90%', lineHeight:1.3}}>
                <Checkbox 
                  checked={tnc}
                  style={{alignItems:'baseline', padding:0, marginRight:3, marginTop:-5, marginLeft:-3, backgroundColor:'white', height:20}}
                  onChange={(e)=> setTnc(e.target.checked)}
                />
                I have read, understood and accepted the 
                <a
                  href="https://resources.latitudefinancial.com/assets/merchant-privacy-notice.pdf"
                  target="_blank"
                  rel="noreferrer"
                  style={{marginLeft: 3}}
                >
                  privacy policy
                </a>
                .
            </div>
           

            <LoadingButton variant="contained" type="submit" loading={loading} disabled={!tnc}
                    style={{width: '90%', height: 50, backgroundColor: tnc ? '#0061EE' : '#D3D3D3', textTransform: 'none', fontFamily:'Roboto', fontWeight:500, fontSize:18, marginTop: 20 }} 
                    onClick={handleRegister}>
                Next
            </LoadingButton>
          </form>
          <div style={{display:'flex', flexDirection: width > 700 ? 'row' : 'column', marginBlock: 10, alignItems: width > 700 ? 'flex-start' : 'center'}}>
            <Text>Already have an account?</Text>
            <div 
              style={{marginLeft: width > 750 ? 5 : 0, marginTop: 2, color: '#0061EE', cursor: 'pointer'}} 
              onClick={() => {
                setLoginPage(true);
                setError('');
                setMessage('');
              }}>
                <strong>Login</strong>
             </div>
          </div>
          {
            message && 
            <Text color="conditional.positive.text" align='center'>
              {message}
            </Text>
          }
          {
            error && 
            <Text color="conditional.negative.text" align='center'>
              {error}
            </Text>
          }

    </div>
  )

  const handleLogin = (e) => {
      handleReCaptchaVerify();

      e.preventDefault();

      if(!captcha) {
        setError('Recaptcha error');
        return;
      }

      setLoading(true);
      props.refreshAuth();
      setError('');
      setMessage('');
      let type = validateUsername(username);
      if (type){
        let id = type === 'mobile' ? username.replace(/[^\d]/g, '') : username;
        props.get2FA({id, captcha});
      }
      else
        setLoading(false);
  }

  const renderLogin = (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', padding: 20,  width: width > 750 ? 450 : 0.7*width, height: 290, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
        <Text as="h4" align='center' textStyle="heading4">Merchant User Questionnaire</Text>
        <div style={{marginTop: 5}}>
          <Text as="h4" align='center' textStyle="heading4">Login</Text>
        </div>        
        <form onSubmit={handleLogin} style={{width:'100%', display:'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Input
              id='username'
              placeholder='Email or AU Mobile number'
              value={username}
              style={{marginTop: 20, width: '90%'}}
              onChange={e=>onInputChange(e, 'username')}
            />
            <LoadingButton variant="contained" loading={loading}
                    style={{width: '90%', height: 50, backgroundColor: '#0061EE', textTransform: 'none', fontFamily:'Roboto', fontWeight:500, fontSize:18, marginTop: 20 }} 
                    onClick={handleLogin}>
                Next
            </LoadingButton>
          </form>
          <div style={{display:'flex', flexDirection: width > 700 ? 'row' : 'column', marginBlock: 10, alignItems: width > 700 ? 'flex-start' : 'center'}}>
            <Text>Don't have an account?</Text>
            <div 
              style={{marginLeft: width > 750 ? 5 : 0, marginTop: 2, color: '#0061EE', cursor: 'pointer'}} 
              onClick={() => {
                setLoginPage(false);
                setError('');
                setMessage('');
              }}>
                <strong>Sign up</strong>
             </div>
          </div>
          {
            message && 
            <Text color="conditional.positive.text" align='center'>
              {message}
            </Text>
          }
          {
            error && 
            <Text color="conditional.negative.text" align='center'>
              {error}
            </Text>
          }
    </div>
  );

  const handleOTPSubmit = (e) => {
    handleReCaptchaVerify();
    e.preventDefault();

    if(!captcha) {
      setError('Recaptcha error');
      return;
    }

    setLoading(true);
    props.refreshAuth();
    setError('');
    setMessage('');
    let type = validateUsername(username);
    let id = type === 'mobile' ? username.replace(/[^\d]/g, '') : username;
    props.login({id, otp: password, captcha});
  }
  
  const render2FA = (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', paddingBlock:20, paddingInline:30, width: width > 750 ? 350 : 0.7*width, height: width > 750 ? 320 : width > 280 ? 300 : 350, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
        <div 
          style={{alignSelf: 'flex-start', marginLeft: -30, marginTop: width > 750 ? 0 : 10, marginBottom: width > 280 ? 25 : 30, display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer'}} 
          onClick={()=>{
            setLoginPage(true);
            setPasswordPage(false);
            props.refreshAuth();
            setMessage('');
            setError('');
          }}
        >
          <Icon name="chevron-left" color="grey.t75" />
          <Text><strong>switch user</strong></Text>
        </div>
        <Text as="h4" textStyle="heading4" align='center'>{`Enter the OTP sent to your ${username.split('@').length === 2 ? 'email' : 'mobile'}`}</Text>
        <form onSubmit={handleOTPSubmit} style={{width:'100%', display:'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Input
              id='password'
              placeholder='Enter OTP'
              autoComplete='one-time-code'
              type='number'
              value={password}
              style={{marginTop: 20, width: '90%'}}
              onChange={e=>onInputChange(e, 'password')}
            />
            <LoadingButton variant="contained" loading={loading}
                    style={{width: '90%', height: 50, backgroundColor: '#0061EE', textTransform: 'none', fontFamily:'Roboto', fontWeight:500, fontSize:18, marginTop: 20 }} 
                    onClick={handleOTPSubmit}>
                Login
            </LoadingButton>
          </form>
          <div 
            style={{ color: '#0061EE', cursor: 'pointer', marginBlock: 10}} 
            onClick={() => {
              if(!captcha) {
                setError('Recaptcha error');
                return;
              }
              
              props.refreshAuth();
              setError('');
              setMessage('');
              let type = validateUsername(username);
              let id = type === 'mobile' ? username.replace(/[^\d]/g, '') : username;

              props.get2FA({id, captcha});
            }}>
            <strong>Resend Code</strong>
          </div>
          <div style={{marginBottom: width > 750 ? 10 : 15}}>
            {
              message && !error &&
                <Text color="conditional.positive.text" align='center'>
                  {message}
                </Text>
            }
            {
              error && 
                <Text color="conditional.negative.text" align='center'>
                  {error}
                </Text>
            }
          </div>
          
    </div>
  );

  const renderLoading = (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', paddingBlock: error ? 0 : 20, paddingBottom: error ? 20 : 0, paddingInline:30, width: width > 750 ? 350 : 0.7*width, height: width > 750 ? 320 : width > 280 ? 300 : 350, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
      {
        error && 
        <UnauthorizedIcon/>
      }
      <div style={{marginBottom: error ? 20: 40}}>
        <Text as="h4" textStyle="heading4" align='center'>{error ? 'Invalid Token' : !captcha ? 'Logging out' : 'Retrieving User Information'}</Text>
        {
            document.referrer === '' &&
            <Text align='center' textStyle="subtitle2">Please close this browser</Text>
        }
      </div>
      {
        error ? 
          document.referrer !== '' &&
            <LoadingButton variant="contained" loading={loading}
              style={{width: '90%', height: 50, backgroundColor: '#0061EE', textTransform: 'none', fontFamily:'Roboto', fontWeight:500, fontSize:18, marginTop: 20 }} 
              onClick={()=> { 
                window.close();
                if (window.closed === false) {
                    alert("Sorry, you'll have to close this browser tab yourself.");
                }
              }}>
              Close
            </LoadingButton>
          :
          <LoadingIcon size='medium'/>
      }
    </div>
  );

  return (
    <div style={{display:'flex', flexDirection: 'column', alignItems: 'center', justifyContent:'center', width: width, height:window.innerHeight, backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' viewBox=\'0 0 907.087 907.087\'%3E%3Cdefs%3E%3ClinearGradient id=\'linear-gradient\' x1=\'0.5\' y1=\'0.006\' x2=\'0.5\' y2=\'1.993\' gradientUnits=\'objectBoundingBox\'%3E%3Cstop offset=\'0\' stop-color=\'%23f2eeea\'/%3E%3Cstop offset=\'0.537\' stop-color=\'%23fff\'/%3E%3C/linearGradient%3E%3CclipPath id=\'clip-path\'%3E%3Crect id=\'Rectangle_2\' data-name=\'Rectangle 2\' width=\'907.087\' height=\'907.087\' transform=\'translate(0 3796.621)\' fill=\'url(%23linear-gradient)\'/%3E%3C/clipPath%3E%3ClinearGradient id=\'linear-gradient-3\' x1=\'0.196\' y1=\'0.5\' x2=\'-0.298\' y2=\'0.5\' xlink:href=\'%23linear-gradient\'/%3E%3ClinearGradient id=\'linear-gradient-4\' x1=\'0.237\' y1=\'0.5\' x2=\'0.503\' y2=\'0.5\' xlink:href=\'%23linear-gradient\'/%3E%3C/defs%3E%3Cg id=\'Group_2\' data-name=\'Group 2\' transform=\'translate(0 -3796.621)\'%3E%3Crect id=\'Rectangle_1\' data-name=\'Rectangle 1\' width=\'907.087\' height=\'907.087\' transform=\'translate(0 3796.621)\' fill=\'url(%23linear-gradient)\'/%3E%3Cg id=\'Group_1\' data-name=\'Group 1\' clip-path=\'url(%23clip-path)\'%3E%3Cpath id=\'Path_1\' data-name=\'Path 1\' d=\'M1921.905 2783.693l-272.5-190.805L559.022 4150.119 2116.252 5240.5 2307.057 4968 1022.326 4068.426Z\' fill=\'url(%23linear-gradient-3)\'/%3E%3Cpath id=\'Path_2\' data-name=\'Path 2\' d=\'M1873.11 1690.318l-538.47-377.041L-820.018 4390.447 2257.15 6545.1l377.041-538.47L95.494 4229.018Z\' fill=\'url(%23linear-gradient-4)\'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")'}}>
        {redirect && <Navigate to={`/quiz/${moduleName}`}/>}
        <div style={{marginBottom: 20}}>
          <Logo  name="latitude" color="primary.blue.t100"/>
        </div>
        {loadingPage? renderLoading : passwordPage ? render2FA : loginPage ? renderLogin : renderSignup}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);