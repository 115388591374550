import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import {Text} from 'basis';

export default function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', paddingInline: props.direction === 'column' ? 0: '16px', flexDirection: props.direction ?? 'row' }}>
      <Box sx={{ width: props.direction === 'column' ? '100%' : '85%', mr: props.direction === 'column' ? 0 : 1 }}>
        <LinearProgress 
          variant="determinate" 
          sx={{
            '& .MuiLinearProgress-bar1Determinate': {backgroundColor: '#0046AA'}
          }}
          {...props} 
        />
      </Box>
      <Box sx={{ width: props.direction === 'column' ? '100%' : 130, marginTop: props.direction === 'column' ? '10px' : 0 }}>
        <Text textStyle="subtitle2" align={props.direction === 'column' ? 'center' : 'left'}>
          <strong>{`${Math.round(props.value,)}% Complete`}</strong>
        </Text>
      </Box>
    </Box>
  );
}