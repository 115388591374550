import axios from 'axios';
import { 
    updateCompleted, notifyCompletedError, 
    updateProgress, notifyProgressError, 
    updateSaveStatus, updateResult, 
    notifyResultError, updateResetStatus 
} from '../slice/TrainingSlice';
import { refreshAuth } from '../slice/UserSlice';

const url = process.env.REACT_APP_API_URL;

export function getCompleted(auth){
    return function (dispatch) {
        let header = {
            'Authorization': `Bearer ${auth}`,
            'Content-Type': 'text/plain'            
        };

        //200 OK = return user object that was saved with /save (if any)
        return axios.get( `${url}/completedmodules`, { headers: header } )
            .then(res => { 
                if (res.status === 200){
                    dispatch(updateCompleted(res.data))
                    console.log(res.data)
                }
            })
            .catch(function (error) {
                if (error.name === 'AxiosError'){       
                    if (error.response.status === 401){
                        window.sessionStorage.removeItem('authDetails')
                        dispatch(refreshAuth());         
                    }             
                    dispatch(notifyCompletedError(error.response.data.error ?? 'Unknown error'));
                }
                else
                    dispatch(notifyCompletedError(error.error));
                console.log(error);
            });
    }
}

export function getProgress(auth){
    return function (dispatch) {
        let header = {
            'Authorization': `Bearer ${auth}`,
            'Content-Type': 'text/plain'            
        };

        //200 OK = return user object that was saved with /save (if any)
        return axios.get( `${url}/progress`, { headers: header } )
            .then(res => { 
                if (res.status === 200){
                    dispatch(updateProgress(res.data))
                    console.log(res.data)
                }
            })
            .catch(function (error) {
                if (error.name === 'AxiosError'){
                    if (error.response.status === 401){
                        window.sessionStorage.removeItem('authDetails')
                        dispatch(refreshAuth());         
                    }                     
                    dispatch(notifyProgressError(error.response.data.error ?? 'Unknown error'));
                }
                else
                    dispatch(notifyProgressError(error.error));
                console.log(error);
            });
    }
}

export function saveProgress(auth, details){
    return function (dispatch) {
        let header = {
            'Authorization': `Bearer ${auth}`,
            'Content-Type': 'text/plain'            
        };
        //details will be [page1(section array), page2, page3, answers(JSON with Qnumber as key and answer as value)]
        return axios.post( `${url}/save`, JSON.stringify(details), { headers: header } )
            .then(res => { 
                if (res.status === 200){
                    dispatch(updateSaveStatus('success'))
                    console.log(res.data)
                }
            })
            .catch(function (error) {
                if (error.name === 'AxiosError'){  
                    if (error.response.status === 401){
                        window.sessionStorage.removeItem('authDetails')
                        dispatch(refreshAuth());         
                    }                   
                    dispatch(updateSaveStatus('failed'));
                }
                else
                    dispatch(updateSaveStatus('failed'));
                console.log(error);
            });
    }
}

export function resetProgress(auth, module){
    return function (dispatch) {
        let header = {
            'Authorization': `Bearer ${auth}`,
            'Content-Type': 'text/plain'            
        };

        let details = {
            name: module,
            data: {}
        }

        return axios.post( `${url}/save`, JSON.stringify(details), { headers: header } )
            .then(res => { 
                if (res.status === 200){
                    dispatch(updateResetStatus('success'))
                    console.log(res.data)
                }
            })
            .catch(function (error) {
                if (error.name === 'AxiosError'){    
                    if (error.response.status === 401){
                        window.sessionStorage.removeItem('authDetails')
                        dispatch(refreshAuth());         
                    }        
                    dispatch(updateResetStatus('failed'));
                }
                else
                    dispatch(updateResetStatus('failed'));
                console.log(error);
            });
    }
}

export function submit(auth, details){
    return function (dispatch) {
        let header = {
            'Authorization': `Bearer ${auth}`,
            'Content-Type': 'text/plain'            
        };

        // let details = {
        // 1: a,
        // 2: b,
        // 3: {a,b,d}
        // }

        return axios.post( `${url}/submit`, JSON.stringify(details), { headers: header } )
            .then(res => { 
                if (res.status === 200){
                    dispatch(updateResult(res.data))
                    console.log(res.data)
                }
            })
            .catch(function (error) {
                if (error.name === 'AxiosError'){      
                    if (error.response.status === 401){
                        window.sessionStorage.removeItem('authDetails')
                        dispatch(refreshAuth());         
                    }               
                    dispatch(notifyResultError(error.response.data.error ?? 'Unknown error'));
                }
                else
                    dispatch(notifyResultError(error.error));
                console.log(error);
            });
    }
}