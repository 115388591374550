import axios from 'axios';
import { notifyAuthError, updateUserDetails, refreshAuth } from '../slice/UserSlice';

const url = process.env.REACT_APP_API_URL;

export function register(details){
    return function (dispatch) {
        let header = {
            'Content-Type': 'text/plain'            
        };

        // let details = {
        //     "id": "0452031760",
        //     "type": "mobile", // or "email"
        //     "firstName": "william",
        //     "lastName": "abdinegara",
        //     "merchantId": "testwilliam"
        // }
        // 200 OK = server will send 2FA immediately, valid for 5 minutes (need sample response)
        // 302 Found = user exists in DB, prompt for 2FA (need sample response)
        // 400 Bad Request = invalid input, prompt user to retry
        return axios.post( `${url}/register`, JSON.stringify(details), { headers: header } )
            .then(res => { 
                if (res.status === 200 || res.status === 201){
                    dispatch(updateUserDetails(res.data))
                    console.log(res.data)
                }
                if (res.status === 302){
                    dispatch(updateUserDetails(res.data))
                }
            })
            .catch(function (error) {
                if (error.name === 'AxiosError'){                    
                    dispatch(notifyAuthError(error.response.data.error ?? 'Unknown error'));
                }
                else
                    dispatch(notifyAuthError(error.error));
                console.log(error);
            });
    }
}

export function get2FA(details){
    return function (dispatch) {
        let header = {
            'Content-Type': 'text/plain'            
        };

        // let details = {"id": "ray@ray.com"} //or {"id": "0400111222"}
        // 200 OK = server will send 2FA immediately, valid for 5 minutes (need sample response)
        // 404 = prompt user to sign up
        return axios.post( `${url}/getcredential`, JSON.stringify(details), { headers: header } )
            .then(res => { 
                if (res.status === 200 || res.status === 201){
                    dispatch(updateUserDetails(res.data))
                    console.log(res.data)
                }
            })
            .catch(function (error) {
                if (error.name === 'AxiosError'){                    
                    dispatch(notifyAuthError(error.response.data.error ?? 'Unknown error'));
                }
                else
                    dispatch(notifyAuthError(error.error));
                console.log(error);
            });
    }
}

export function login(details){
    return function (dispatch) {
        let header = {
            'Content-Type': 'text/plain'            
        };

        // let details = {
        //     "id": "ray@ray.com",
        //     "2fa":"123123",
        //     }
        // 200 OK = will return authToken valid for 8h
        // 400 Error = expired, send another 2fa
        return axios.post( `${url}/login`, JSON.stringify(details), { headers: header } )
            .then(res => { 
                if (res.status === 200 || res.status === 201){
                    dispatch(updateUserDetails({...res.data, loginType:'form'}))
                    console.log(res.data)
                }
            })
            .catch(function (error) {
                if (error.name === 'AxiosError'){                    
                    dispatch(notifyAuthError(error.response.data.error ?? 'Unknown error'));
                }
                else
                    dispatch(notifyAuthError(error.error));
                console.log(error);
            });
    }
}

export function logout(){
    return function (dispatch) {
        window.sessionStorage.removeItem('authDetails')
        dispatch(refreshAuth());         
    }
}

export function oktaLogin(details){
    return function (dispatch) {
        let header = {
            'Content-Type': 'text/plain'            
        };

        // let details = {
        // "accessToken": "ebasd5yu.a.b",
        // "merchantId": "testray",
        // "firstName": "ray",
        // "lastName": "test",
        // }
        // 200 OK = will return authToken valid for 8h
        // 400 Error = expired, send another 2fa
        return axios.post( `${url}/oktalogin`, JSON.stringify(details), { headers: header } )
            .then(res => { 
                if (res.status === 200 || res.status === 201){
                    dispatch(updateUserDetails({...res.data, loginType:'okta'}))
                    console.log(res.data)
                }
            })
            .catch(function (error) {
                if (error.name === 'AxiosError'){                    
                    dispatch(notifyAuthError(error.response.data.error ?? 'Unknown error'));
                }
                else
                    dispatch(notifyAuthError(error.error));
                console.log(error);
            });
    }
}