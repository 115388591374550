import React from "react";
import TextField from '@mui/material/TextField';
import { Text } from 'basis';

export default function Input(props) {
    return <div style={{...props.style}}>
            <div style={{ marginBottom: 5, display: 'flex', flexDirection:'row', justifyContent: 'space-between' }}>
                <Text color="grey.t75"><strong>{props.label}</strong></Text>
                {
                    props.optional && 
                    <div style={{border: '2px solid rgb(65, 65, 65)', borderRadius:12, opacity:0.66, paddingInline:8, lineHeight: 20}}>
                        <Text textStyle="body2" color="grey.t100"><strong>Optional</strong></Text>
                    </div>
                }
            </div>
            <TextField id={props.id} variant="filled" placeholder={props.placeholder}
                autoComplete={props.autoComplete} type={props.type}
                value={props.value} 
                sx={{
                    '& .MuiFilledInput-root': {backgroundColor: '#F2F2F2', borderTopLeftRadius:0, borderTopRightRadius:0},
                    '& .MuiFilledInput-root:before':{borderWidth:0},
                    '& .MuiFilledInput-root:after':{borderWidth:0},
                    '& .MuiInputLabel-root': {fontWeight:300, fontFamily:'Roboto', fontSize: 16},
                    '& .MuiFilledInput-input': {paddingTop:0, paddingBlock: 0, marginLeft:0.5, fontWeight:300, fontFamily:'Roboto', fontSize: 16, height: 48 },
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" }
                }}
                onChange={props.onChange} 
                style={{width:'100%'}}
            />
            {
                props.error ? 
                    <div style={{ paddingTop:8 }}>
                        <Text textStyle="body2" color="conditional.negative.text">{props.error}</Text>
                    </div>
                    :
                    props.helpText ?
                        <div style={{ paddingTop:8 }}>
                            <Text textStyle="body2" color="grey.t75">{props.helpText}</Text>
                        </div>
                    : null
            }
        </div>
}