import React, { useState, useEffect } from "react";
import { Text, RadioGroup, Form, CheckboxGroup } from 'basis';

export default function Question(props) {
    const initializeMultiple = () => {
        let multi = {};
        props.choice.forEach(c => {
            multi[c.key] = props.answer ? props.answer.includes(c.key) : false;
        })
        return multi;
    };

    const [initialValue, setInitialValue] = useState({
                                                        [`q${props.qId}`]: props.multiple ? 
                                                                        initializeMultiple() : 
                                                                        props.answer ?? ''
                                                    });

    useEffect(() => {
        if (props.answer && !props.multiple){
            let init = {}
            init[`q${props.qId}`] = props.answer;
            setInitialValue(init)
        }
        if (props.answer && props.multiple){
            let multi = {};
            props.choice.forEach(c => {
                multi[c.key] = props.answer.includes(c.key);
            })
            let init = {}
            init[`q${props.qId}`] = multi;
            setInitialValue(init)
        }
    }, [props.answer, props.qId, props.choice, props.multiple]);

    const handleChange = (e) => {
        props.onChange && props.onChange(e.selectedOption.value);
    };

    const handleChangeMulti = (e) => {
        let answers = [];
        Object.keys(e.value).forEach(key => {
            if (e.value[key])
                answers.push(key);
        })
        props.onChange && props.onChange(answers.join());
    };

    props.multiple && console.log(initialValue, props.answer)
    return (
        <div style={{marginBlock:10, ...props.style}}>
            <div style={{display:'flex', flexDirection:'row', marginBottom:20}}>
                <div style={{display:'flex', background: '#0046AA', width:32, height:32, borderRadius:90, justifyContent: 'center', alignItems: 'center', marginRight: 16 }}>
                    <Text color="white"><strong>{`${props.qNumber}`}</strong></Text>
                </div>
                <div style={{width:'90%'}}>
                    <Text textStyle="body1" color="black">{`${props.question}`}</Text>
                </div>
            </div>
            {
                !props.multiple &&
                <Form initialValues={initialValue}>
                    <RadioGroup
                        name={`q${props.qId}`}
                        columns={1}
                        options={props.choice}
                        onChange={handleChange}
                    />
                </Form>
            }
            {
                props.multiple &&
                <div>
                    <Text><strong>Select all that apply</strong></Text>
                    <Form initialValues={initialValue}>
                        <CheckboxGroup
                            name={`q${props.qId}`}
                            options={props.choice}
                            onChange={handleChangeMulti}
                        />
                    </Form>
                </div>
            }
        </div>
    )
}
