import { createSlice } from '@reduxjs/toolkit'

export const UserSlice = createSlice({
  name: 'user',
  initialState: {
    authDetails: null,
    authError: null,
  },
  reducers: {
    updateUserDetails: (state, action) => {
      state.authDetails = action.payload;
      state.authError = null;        
    },
    notifyAuthError: (state, action) => {
      state.authError = action.payload;
      state.authDetails = null;        
    },
    refreshAuth: (state) => {
      state.authDetails = null;        
      state.authError = null;      
    },
  }
})

// Action creators are generated for each case reducer function
export const { updateUserDetails, notifyAuthError, refreshAuth } = UserSlice.actions

export default UserSlice.reducer