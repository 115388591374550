import { quiz as training1} from '../contents/PBLtraining';


export function objectsEqual(o1, o2){
    //if both null or undefined
    if (!o1 && !o2)
        return true;

    //if one of then null or undefined
    if (!o1 || !o2)
        return false

    return typeof o1 === 'object' && Object.keys(o1).length > 0 ? 
                Object.keys(o1).length === Object.keys(o2).length 
                && 
                Object.keys(o1).every(key => objectsEqual(o1[key], o2[key]))
        : o1 === o2;
}

export function arraysEqual(a1,a2){
    //a1 and a2 must not be null/undefined, a1 and a2 have same length, check each element of a1 against a2
    return a1 && a2 && a1.length === a2.length && a1.every((a1Element, idx) => a2.find(a2Element => objectsEqual(a1Element, a2Element)))
}

export function randomizeArray(arr){
    let array = Object.keys(arr);
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
}

export function getQuiz(moduleName){
    switch (moduleName){
        case 'training1': return training1;
        default: return training1;
    }
}