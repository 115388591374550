import React
  //,{ useState, useEffect } 
  from 'react';
//import { useDispatch } from 'react-redux'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import StickyBox from "react-sticky-box";
import { BasisProvider, defaultTheme } from "basis";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// import Navbar from './components/Navbar';
// import Sidebar from './components/Sidebar';
import Login from './containers/Login';
import Quiz from './containers/Quiz';
//import { associateMenu } from './slice/MenuSlice';

function App() {
  // const [width, setWidth] = useState(window.innerWidth);

  // const dispatch = useDispatch()

  // useEffect(() => {
  //   function handleResize(){
  //     setWidth(window.innerWidth);
  //   }

  //   dispatch(associateMenu(window.location.pathname.split('/')[1]));

  //   window.addEventListener('resize', handleResize);
  //   Navbar.HEIGHT_MAP = {
  //     default: width < 750 ? 180 : 80,
  //   }

  //   return function removeListener(){
  //     window.removeEventListener('resize', handleResize);
  //   }
  // })

  // Navbar.ID = 'StickyNav';
  // Navbar.HEIGHT_MAP = {
  //   default: width < 750 ? 180 : 80,
  // }

  return (
    <BasisProvider theme={defaultTheme}>
      <React.Fragment>
        <Router>
          <Routes>
            <Route exact path='/' element={
              <GoogleReCaptchaProvider
                useEnterprise={true}
                reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
                <Login />
              </GoogleReCaptchaProvider>
            } />
            <Route exact path='/quiz/:id' element={<Quiz />} />
          </Routes>
        </Router>
      </React.Fragment>
    </BasisProvider>
  );
}

export default App;
